import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import {Avatar, Typography} from "@material-ui/core"
import PushNotification from "../../../components/common/PushNotification"
import {stringToColor} from "../../../utils/random-color"
import { withStyles } from '@material-ui/styles';

const classes = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 200,
  },
  upload: {
    padding: '18.5px 14px',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    border: '1px solid #c7c7c7',
    borderRadius: 5,
    width: 200,
    height: 56,
    overflow: 'hidden',

  },
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 15,
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    fontWeight: 700,
    color: "#222a42b3",
    fontSize: "0.875rem",
  },
  tableBody: {
    fontWeight: 400,
    color: "#222a42b3",
    fontSize: "0.875rem",
  },
});
const TrainerReviews = (props) => {
  const { reviews } = props;
  console.log("reviews", reviews);
  
  return (
          <div>
            <DialogTitle
              id="form-dialog-title"
            >Trainer Reviews
            </DialogTitle>
            <DialogContent>
            <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>User Name</TableCell>
            <TableCell className={classes.tableHead}>Date</TableCell>
            <TableCell className={classes.tableHead}>Event Name</TableCell>
            <TableCell className={classes.tableHead}>Rating</TableCell>
            <TableCell className={classes.tableHead}>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reviews && reviews.map((review, index) => (
            !review.eventName || !review.eventDate ? null :
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {review && review.userName || 'N/A' }
              </TableCell>
              <TableCell className={classes.tableBody}>{review.eventDate || 'N/A'}</TableCell>
              <TableCell className={classes.tableBody}>{review.eventName || 'N/A'}</TableCell>
                <TableCell className={classes.tableBody}>{review.rating || 'N/A'}</TableCell>
                <TableCell className={classes.tableBody}>{review.comment || 'N/A'}</TableCell>
            </TableRow>
          )) 
        }
        </TableBody>
      </Table>
        {reviews && reviews.length < 1 && <DialogContentText>
            No reviews found for this trainer yet!
        </DialogContentText> }
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose} color="primary">
                Cancel
              </Button>
             

            </DialogActions>
          </div>
        );
};

export default withStyles(classes)(TrainerReviews);
/* 
ServiceDialog.defaultProps = {
  action: 'edit',
  service: {
    title: '',
    subtitle: '',
    price: '',
    location: '',
    image: '',
    bookingLink: '',
    image:  '',
    id: '',
  },
};
 */