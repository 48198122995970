import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContext from './SnackbarContext';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const {
    className, message, onClose, variant, ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      )}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={() => onClose()}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

// MySnackbarContentWrapper.propTypes = {
//     className: PropTypes.string,
//     message: PropTypes.string,
//     onClose: PropTypes.func,
//     variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
// };

const useStyles2 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomSnackbar(props) {
  const classes = useStyles2();

  const [alert, setAlert] = React.useState(false);
  const [settings, setSettings] = React.useState({ variant: 'error', message: '' });

  function handleOpenSnackbar(variant, message) {
    setSettings({ variant, message });
    setAlert(true);
  }

  function closeSnackbar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  }

  let snackbar;
  switch (settings.variant) {
  case 'error': {
    snackbar = (
      <MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message={settings.message}
        onClose={closeSnackbar}
      />
    );
    break;
  }
  case 'success': {
    snackbar = (
      <MySnackbarContentWrapper
        variant="success"
        className={classes.margin}
        message={settings.message}
        onClose={closeSnackbar}
      />
    );
    break;
  }
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alert}
        autoHideDuration={5000}
        onClose={closeSnackbar}
      >
        {snackbar}
      </Snackbar>
      <SnackbarContext.Provider
        value={{ ...settings, openSnackbar: handleOpenSnackbar }}
      >
        {props.children}
      </SnackbarContext.Provider>
    </div>
  );
}
