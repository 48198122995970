import React, {Fragment, useEffect, useState} from 'react';
import {withStyles} from '@material-ui/styles';
import {useApolloClient} from '@apollo/client';
import Modal from 'react-modal';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  useTheme,
  Grid,
  FormHelperText,
  Switch,
  Box,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {CSVLink} from 'react-csv';
import Typography from '@material-ui/core/Typography';
import updateAvailableCodes from '../../../graphql/companies/mutation/update-available-codes';
import RefreshButton from '../RefreshButton';
import SnackbarContext from '../../../containers/CustomSnackbar/SnackbarContext';
import deleteCompanyMutation from '../../../graphql/companies/mutation/delete-company';
import updateCompanyMutation from '../../../graphql/companies/mutation/update-company';
import CreateCompanyMutation from '../../../graphql/companies/mutation/create-company';
import sendHealthDeclarationsMutation from '../../../graphql/companies/mutation/send-health-declarations';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
  },
  textFieldDense: {
    marginLeft: 8,
    marginRight: 8,
  },
  selectInput: {
    marginLeft: 8,
    marginRight: 8,
    width: '100%',
    marginTop: 16,
    borderWidth: 1,
    borderColor: 'black',
  },
  test: {
    border: '1px solid #e0e0e0',
  },
  toggler: {fontSize: 15, marginLeft: 20, marginRight: 50},
});

function EditCompany({classes, company}) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [showDialog, setShowDialog] = React.useState(false);

  const [confirmationDialog, setConfirmationDialog] = React.useState(false);

  const {openSnackbar} = React.useContext(SnackbarContext);
  let deleteText = '';

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCompatitonDialog() {
    setShowDialog(true);
  }

  function handleNotCompatitonDialog() {
    setShowDialog(false);
  }
  const [newSite, setNewSite] = React.useState('');
  const [emailToSend, setEmailToSend] = React.useState('');
  const [values, setValues] = React.useState({
    name: company && company.name ? company.name : '',
    emailSuffix: company && company.emailSuffix ? company.emailSuffix : '',
    logo: company && company.logo ? company.logo : '',
    codes: [],
    isPublic: company && company.isPublic !== null ? company.isPublic : false,
    quantity: 0,
    leaderboardAvailable: company && company.leaderboardAvailable !== null ? company.leaderboardAvailable : false,
    needsHealthDeclaration: company && company.needsHealthDeclaration !== null ? company.needsHealthDeclaration : false,
    isPhoneOptional: company && company.isPhoneOptional !== null ? company.isPhoneOptional : false,
    competitionMode: company && company.competitionMode !== null ? company.competitionMode : false,
    contentEnabled: company && company.contentEnabled !== null ? company.contentEnabled : false,
    whatsappLink: company && company.whatsappLink ? company.whatsappLink : '',
    slackLink: company && company.slackLink ? company.slackLink : '',
    sites: company && company.sites ? company.sites : [],
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Update the document title using the browser API
    setValues({
      name: company.name,
      emailSuffix: company.emailSuffix,
      logo: company.logo,
      codes: company.codes,
      masterCode: company.masterCode ? company.masterCode : 'No Valid Code',
      whatsappLink: company.whatsappLink ? company.whatsappLink : '',
      slackLink: company.slackLink ? company.slackLink : '',
      isPublic: company.isPublic ? company.isPublic : false,
      quantity: company.codes ? company.codes.length : 0,
      leaderboardAvailable: company.leaderboardAvailable,
      needsHealthDeclaration: company.needsHealthDeclaration,
      isPhoneOptional: company.isPhoneOptional || false,
      competitionMode: company.competitionMode || false,
      contentEnabled: company.contentEnabled || false,
      sites: company.sites || false,
    });

    console.log(company, values);
  }, [company]);

 

  const client = useApolloClient();

  const handleChange = (event) => {
    console.log(event.target);
    if(event.target.type !== "checkbox"){
      console.log(event.target.value, event.target.name);
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
    else {
      setValues({
        ...values,
        [event.target.name]: event.target.checked ?? event.target.value,
      });
  }
};

  return (
    <>
      <Tooltip TransitionComponent={Zoom} title="Edit Company">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClickOpen}>
          <MoreVertIcon style={{color: 'white'}} color="secondary" />
        </IconButton>
      </Tooltip>

      <Dialog
        fullScreen={false}
        maxWidth="xs"
        scroll="body"
        open={open}
        onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Edit Company Info</DialogTitle>
        <DialogContent>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="name"
              label="Name"
              className={classes.textField}
              value={values.name}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              name="name"
              fullWidth
            />
            <TextField
              id="emailSuffix"
              label="Email Suffix"
              className={classes.textField}
              value={values?.emailSuffix}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              name="emailSuffix"
              fullWidth
            />
            <TextField
              id="logo"
              label="Logo"
              className={classes.textField}
              value={values.logo}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              name="logo"
              fullWidth
            />
            <TextField
              id="whatsappLink"
              label="Whatsapp Url"
              className={classes.textField}
              value={values.whatsappLink}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              name="whatsappLink"
              fullWidth
            />
            <TextField
              id="slackLink"
              label="Slack Url"
              className={classes.textField}
              value={values.slackLink}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              name="slackLink"
              fullWidth
            /><TextField
                  id="site"
                  label="add site"
                  className={classes.textField}
                  value={newSite}
                  onChange={()=>setNewSite(event.target.value)}
                  margin="dense"
                  variant="outlined"
                  name="site"
                  
            />  
             <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      newSite.length > 1
                      &&
                      setValues({
                        ...values,
                        sites: [...values.sites, newSite],
                      });
                    }}
                    disabled={!newSite}>
                   Add Site
                  </Button>
            { values.sites && values.sites.length > 0 ?       
              values.sites.map((site, index) => {
                return (
                <div key={index} style={styles().toggler} >
                  {site && site.length > 0 ? site : 'Site'}
                 {/*  <Tooltip
                    title={site}></Tooltip> */}
                    <Button
                      onClick={() => {
                        setValues({
                          ...values,
                          sites: values.sites.filter((item) => item !== site),
                        });
                      }}
                      color='warning'
                      size='small'
                      variant="text">
                X
              </Button>
                  
                </div>
             )
                }
                ):
                null
              } 
             
            <FormControl variant="outlined" className={classes.selectInput}>
              <InputLabel htmlFor="outlined-age-simple">Public</InputLabel>
              <Select
                MenuProps={{classes: {list: classes.test}}}
                value={values.isPublic}
                onChange={handleChange}
                name="isPublic"
                input={
                  <OutlinedInput
                    labelWidth={50}
                    name="verified"
                    id="outlined-age-simple"
                  />
                }>
                <MenuItem key={1} value>
                  True
                </MenuItem>
                <MenuItem key={2} value={false}>
                  False
                </MenuItem>
              </Select>
            </FormControl>
            
            <div style={{marginTop: 16}}>
           
              <InputLabel>Code Generator</InputLabel>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    className={classes.textFieldDense}
                    value={values.quantity}
                    onChange={handleChange}
                    name="quantity"
                    type="number"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
           
                <Grid item xs={8} md={30}>
                  <Button
                    style={{height: '100%'}}
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      !values.quantity > 0
                        ? openSnackbar('error', 'Quantity must be 1 or larger')
                        : client
                            .mutate({
                              mutation: updateAvailableCodes,
                              variables: {
                                companyId: company._id,
                                count: parseInt(values.quantity),
                              },
                            })
                            .then(
                              () => {},
                              (error) => {
                                // console.log(error.graphQLErrors);
                              },
                            );
                    }}>
                    Generate
                  </Button>
                </Grid>
                
                <div style={styles().toggler}>
                  {values.leaderboardAvailable ? 'Leaderboard' : 'Leaderboard'}
                  <Tooltip
                    title={
                      values.leaderboardAvailable
                        ? 'Leaderboard'
                        : 'Leaderboard'
                    }>
                    <Switch
                      checked={values.leaderboardAvailable}
                      onChange={handleChange}
                      name="leaderboardAvailable"
                      color="primary"
                    />
                  </Tooltip>
                </div>
                <div style={styles().toggler}>
                  {'Phone Optional'}
                  <Tooltip title={'Phone Optional'}>
                    <Switch
                      checked={values.isPhoneOptional}
                      onChange={handleChange}
                      name="isPhoneOptional"
                      color="primary"
                    />
                  </Tooltip>
                </div>
                <div style={styles().toggler}>
                  {'Health Declarations'}
                  <Tooltip title={'Health Declarations'}>
                    <Switch
                      checked={values.needsHealthDeclaration}
                      onChange={handleChange}
                      name="needsHealthDeclaration"
                      color="primary"
                    />
                  </Tooltip>
                </div>
                <div style={styles().toggler}>
                  {'Competition Mode'}
                  {!company.competitionMode && values.competitionMode && (
                    <Dialog
                      style={{
                        content: {justifyContent: 'center'},
                        marginLeft: '32%',
                        marginTop: '20%',
                        width: 'fit-content',
                        height: 'fit-content',
                        justifyContent: 'center',
                      }}
                      maxWidth={maxWidth}
                      fullScreen={fullScreen}
                      open={showDialog}
                      onClose={handleNotCompatitonDialog}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          m: 'auto',
                          width: 'fit-content',
                        }}>
                        <DialogTitle
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          BeAware!
                        </DialogTitle>
                        <DialogContent>
                          <h2
                            style={{
                              textAlign: 'center',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            Are you sure you would like to reset company points?
                          </h2>
                          <Button
                            style={{
                              backgroundColor: '#419EF9',
                              color: 'white',
                            }}
                            onClick={handleNotCompatitonDialog}>
                            Yes I'm sure
                          </Button>
                          <Button
                            style={{
                              backgroundColor: 'primary',
                              color: 'secondary',
                              float: 'right',
                            }}
                            onClick={() =>
                              setValues((prev) => ({
                                ...prev,
                                competitionMode: !prev.competitionMode,
                              }))
                            }>
                            No
                          </Button>
                        </DialogContent>
                      </Box>
                    </Dialog>
                  )}
                  <Tooltip title={'Competition Mode'}>
                    <Switch
                      checked={values.competitionMode}
                      onClick={handleCompatitonDialog}
                      onChange={handleChange}
                      name="competitionMode"
                      color="primary"
                    />
                  </Tooltip>
                </div>
                <div style={styles().toggler}>
                  {'Content Enabled'}
                  <Tooltip title={'Content Enabled'}>
                    <Switch
                      checked={values.contentEnabled}
                      onChange={handleChange}
                      name="contentEnabled"
                      color="primary"
                    />
                  </Tooltip>
                </div>
                <Grid item xs={6} md={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!(!!values.codes && values.codes.length > 0)}>
                    <CSVLink
                      style={{textDecoration: 'none', color: 'white'}}
                      filename={`user-codes-${values.name}.csv`}
                      data={
                        values.codes ? values.codes.map((code) => [code]) : ''
                      }>
                      Download
                    </CSVLink>
                  </Button>
                </Grid>
                
              </Grid>
              <FormHelperText
                style={{display: 'flex', alignItems: 'center', minHeight: 25}}
                error={!(!!values.codes && values.codes.length > 0)}>
                Available Codes: {!!values.codes && values.codes.length} |
                Master: {values.masterCode}
                <RefreshButton _id={company._id} />
              </FormHelperText>
            </div>
          </form>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            marginTop: 10,
            marginBottom: 10,
            marginRight: 24,
            marginLeft: 24,
          }}>
            <TextField
                  id="emailToSend"
                  label="email to send health declarations"
                  className={classes.textField}
                  value={emailToSend}
                  onChange={()=> setEmailToSend(event.target.value)}
                  margin="dense"
                  variant="outlined"
                  name="emailToSend"
                  
            />  
             <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      emailToSend.length > 10
                      &&
                      console.log(company._id, emailToSend);
                      client
                        .mutate({
                          mutation: sendHealthDeclarationsMutation,
                          variables: {
                            _id: company._id,
                            email: emailToSend,
                          },
                        })
                        .then(() => {
                          handleClose();
                          window.location.reload();
                        }
                        )
                        .catch((error) => {
                          console.log(error);
                        }
                        );
                    }}
                    disabled={!emailToSend && !emailToSend.length > 10}>
                   send Health Declarations
                  </Button>
            
             
          <SnackbarContext.Consumer>
            {(value) => (
              <Button
                onClick={() => {
                  setConfirmationDialog(true);
                }}
                style={{backgroundColor: '#e37045', color: '#fff', flex: 1}}
                variant="contained">
                DELETE COMPANY
              </Button>
            )}
          </SnackbarContext.Consumer>
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <SnackbarContext.Consumer>
            {(value) => (
              <Button
                onClick={() => {
                  const {
                    name,
                    emailSuffix,
                    logo,
                    isPublic,
                    leaderboardAvailable,
                    whatsappLink,
                    slackLink,
                    needsHealthDeclaration,
                    isPhoneOptional,
                    competitionMode,
                    contentEnabled,
                    sites
                  } = values;
                  return name === '' || emailSuffix === '' || logo === ''
                    ? value.openSnackbar(
                        'error',
                        'Please make sure there are no empty fields',
                      )
                    : client
                        .mutate({
                          mutation: updateCompanyMutation,
                          variables: {
                            _id: company._id,
                            name,
                            emailSuffix: emailSuffix
                              ? emailSuffix.trim().toLowerCase()
                              : '',
                            needsHealthDeclaration,  
                            logo,
                            whatsappLink,
                            slackLink,
                            leaderboardAvailable,
                            isPublic,
                            isPhoneOptional,
                            competitionMode,
                            contentEnabled,
                            sites
                          },
                        })
                        .then(() => {
                          handleClose();
                          window.location.reload();
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                }}
                color="primary">
                OK
              </Button>
            )}
          </SnackbarContext.Consumer>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={confirmationDialog}>
        <DialogTitle id="confirmation-dialog-title">Beware!</DialogTitle>
        <DialogContent>
          <Typography>
            This Operation Cannot Be Undone
            <br />
            <br />
          </Typography>
          <Typography>
            Type 'DELETE' in the field below and click Yes to permanently delete
            this company
            <br />
          </Typography>
          <TextField
            id="delete"
            label="DELETE"
            inputProps={{
              style: {textTransform: 'uppercase'},
            }}
            onChange={(value) => {
              deleteText = value.target.value;
            }}
            type="text"
            variant="outlined"
            fullWidth={false}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setConfirmationDialog(false);
              deleteText = '';
            }}
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (deleteText.toUpperCase() === 'DELETE') {
                client
                  .mutate({
                    mutation: deleteCompanyMutation,
                    variables: {_id: company._id},
                  })
                  .then(() => {
                    deleteText = '';
                    setConfirmationDialog(false);
                    handleClose();
                    window.location.reload();
                  })
                  .catch((error) => {
                    deleteText = '';
                    console.log(error);
                  });
              }
            }}
            color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

EditCompany.defaultProps = {
  company: {name: '', emailSuffix: '', logo: ''},
};

export default withStyles(styles)(EditCompany);
