import gql from 'graphql-tag';

const GET_MAKERS_STATISTICS = gql`
  query getMakersStatistics($_id: String!) {
    getMakersStatistics(_id: $_id) {
      recurring_event_name
      total_participation_rate
      average_participation_rate
      site
      events {
        date
        available_spots
        number_of_participants
        registered_users
        attended_users
        site
      }
    }
  }
`;

export default GET_MAKERS_STATISTICS;