import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, Divider } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import ActionMenu from '../../../components/common/ActionMenu';
import EventModal from './EventModal';
import CurrentUserContext from '../../../containers/CurrentUser/CurrentUserContext';
import logo from '../../../Assets/logo-white.png';

const R = require('ramda');

moment.tz.setDefault('Asia/Jerusalem');

const styles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    position: 'relative',
  },
  cardContent: {
    flexGrow: 1,
    alignItems: 'flex-start',
    paddingTop: 0,
  },
  timeCaption: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  coinTag: {
    backgroundColor: '#85d7a9',
    paddingRight: 10,
    paddingLeft: 10,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
});

function EventCard(props) {
  const {
    companyName,
    event,
    id,
  } = props;
  const { classes } = props;
  const userContext = React.useContext(CurrentUserContext);

  const [internalEvent, setInternalEvent] = React.useState(event);
  const [state, setState] = React.useState({
    coins: 0,
    takenSpots: 0,
    totalSpots: 0,
    title: 'No title',
    location: 'Not Specified',
    description: 'No description',
    date: 'No date',
    time: 'No time',
    instructor: 'No instructor',
    company: 'No company',
    hoursTillEvent: 0,
    image: '',
  });

  React.useEffect(() => {
    setInternalEvent(event);
  }, [event]);

  React.useEffect(() => {
    setState({
      coins: R.pathOr('0', ['coins'])(internalEvent),
      takenSpots: R.pathOr(0, ['users', 'length'])(internalEvent),
      totalSpots: R.pathOr(0, ['totalSpots'])(internalEvent),
      title: R.pathOr('No title', ['title'])(internalEvent),
      location: R.pathOr('Not Specified', ['location'])(internalEvent),
      description: R.pathOr('No description', ['description'])(internalEvent),
      date: R.pathOr('No date', ['date'])(internalEvent),
      time: moment(internalEvent.date).format('llll'),
      instructor: R.pathOr('No instructor', ['instructor'])(internalEvent),
      company: R.pathOr('No company', ['company'])(internalEvent),
      hoursTillEvent: R.pathOr(0, ['hoursTillEvent'])(internalEvent) || moment(internalEvent.date).diff(moment(), 'hours'),
      image: R.pathOr('', ['image'])(internalEvent),
      companyDisplayName: companyName || internalEvent?.company?.name || 'No Company'
    });
  }, [internalEvent]);



  return (
    <Card className={classes.card}>
      <EventModal setInternalEvent={setInternalEvent} event={internalEvent} refetch={props.refetch}>
        <CardMedia className={classes.cardMedia} image={state.image} title={state.title}>
          <div className={classes.coinTag}>{state.coins} Coins Reward</div>
        </CardMedia>
      </EventModal>

      <CardHeader
        action={
          userContext.currentUser.roles.includes('admin') ? (
            <ActionMenu card={internalEvent} />
          ) : null
        }
        title={state.title}
        titleTypographyProps={{ style: { textTransform: 'capitalize' } }}
        subheader={`${moment(state.date).format(' MMMM Do, HH:mm')}${internalEvent.dateEnd
          ? ` - ${moment(internalEvent.dateEnd).format(' MMMM Do, HH:mm')}`
          : ''
          }`}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.timeCaption}>
          <Typography gutterBottom variant="body1" color="textSecondary">
            {moment(state.date).fromNow()}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {`${state.takenSpots}/${state.totalSpots} spots `}
          </Typography>
        </div>
        <Typography>{state.description}</Typography>
      </CardContent>
      <Divider variant="fullWidth" />
      <CardActions>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Typography color="primary" variant="caption">
            {state.location} | {R.pathOr('Unknown', ['firstName'])(state.instructor)}&nbsp;
            {R.pathOr('', ['lastName'])(state.instructor)} |&nbsp;
            {state.companyDisplayName}
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
}

EventCard.defaultProps = {
  saved: false,
};

EventCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventCard);
