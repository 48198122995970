import gql from 'graphql-tag';

const getOneEventDetails = gql`
  query getOneEventDetails($_id: String!) {
    getOneEventDetails(_id: $_id) {
      _id
      title
      style
      instructor {
        firstName
        lastName
        _id
      }
      users {
        _id
        firstName
        lastName
        email
      }
      location
      date
      dateEnd
      duration
      totalSpots
      description
      image
      coins
      enablePush
      isLive
      zoomUrl
      site
      verifiedUsers {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

export default getOneEventDetails;