import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import { useApolloClient } from '@apollo/client';
import CurrentCompanyContext from '../../../containers/CurrentCompany/CurrentCompanyContext';
import SnackbarContext from '../../../containers/CustomSnackbar/SnackbarContext';
import updateServiceMutation from '../../../graphql/services/mutation/update-service';
import createServiceMutation from '../../../graphql/services/mutation/create-service';
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 200,
  },
  upload: {
    padding: '18.5px 14px',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    border: '1px solid #c7c7c7',
    borderRadius: 5,
    width: 200,
    height: 56,
    overflow: 'hidden',

  },
});
const ServiceDialog = (props) => {
  const { service, company } = props;
  const [values, setValues] = React.useState({
    _id: service._id || '',
    title: service.title || '',
    company: company,
    subtitle: service.subtitle || '',
    price: service.price || '',
    location: service.location || '',
    image: service.image || '',
    bookingLink: service.bookingLink || '',
    duration: service.duration || '',   

  });
  console.log(company);
  const client = useApolloClient();

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    console.log(variables);
  };

  const handleSubmit = () => {
    console.log(formData);
    props.handleClose();
  };

  const { classes } = props;

  const {
    title, subtitle, price, location, image, bookingLink, duration
  } = values;

  const formData = {
    title, subtitle, price, location, image, bookingLink, duration, company,
  };



  
  const variables = props.action === 'create' ? { ...formData } : { ...formData, _id: service._id };
  const mutation = props.action === 'create' ? createServiceMutation : updateServiceMutation;
console.log(props.action, variables, mutation);
  return (
    <CurrentCompanyContext.Consumer>
      {(value) => {
        const { currentCompany } = value;
        return (
          <div>
            <DialogTitle
              id="form-dialog-title"
            >{props.action === 'create' ? 'Create' : 'Edit'} Service
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Here you can create the details of a specific service.
                Please note that changes will be visible to all relevant users once submitted.
              </DialogContentText>
              <form className={classes.container} noValidate autoComplete="off">
                <TextField
                  id="title"
                  label="Title"
                  className={classes.textField}
                  value={values.title}
                  onChange={handleChange('title')}
                  margin="normal"
                  variant="outlined"
                    required

                />
                <TextField
                  id="subtitle"
                  label="Subtitle"
                  className={classes.textField}
                  value={values.subtitle}
                  onChange={handleChange('subtitle')}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  id="price"
                  label="Price"
                  className={classes.textField}
                  value={values.price}
                  onChange={handleChange('price')}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  id="location"
                  label="loaction"
                  className={classes.textField}
                  value={values.location}
                  onChange={handleChange('location')}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  id="image"
                  label="Image"
                  className={classes.textField}
                  value={values.image}
                  onChange={handleChange('image')}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  id="duration"
                  label="duration"
                  className={classes.textField}
                  value={values.duration}
                  onChange={handleChange('duration')}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  id="bookingLink"
                  label="bookingLink"
                  className={classes.textField}
                  value={values.bookingLink}
                  onChange={handleChange('bookingLink')}
                  margin="normal"
                  variant="outlined"
                  required
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose} color="primary">
                Cancel
              </Button>
              <SnackbarContext.Consumer>
                {(value) => (
                  <Button
                    onClick={() => 
                        (variables.title === '' || variables.subtitle === ''
                     || variables.price === '' || variables.location === '' || variables.image === '' 
                     || variables.bookingLink === '' || variables.duration === ''
                      ? value.openSnackbar('error', 'Please make sure there are no empty fields')
                      : client.mutate({
                        mutation,
                        variables,
                      })
                        .then(async ({ data, error }) => {
                          props.handleClose();
                          value.openSnackbar('success', 'Service created Successfully.');
                        })
                        .catch((error) => {
                          console.log(error);
                        }))}
                    color="primary"
                  >
                    OK
                  </Button>
                )}
              </SnackbarContext.Consumer>

            </DialogActions>
          </div>
        );
      }}
    </CurrentCompanyContext.Consumer>
  );
};

export default withStyles(styles)(ServiceDialog);

ServiceDialog.defaultProps = {
  action: 'edit',
  service: {
    title: '',
    subtitle: '',
    price: '',
    location: '',
    image: '',
    bookingLink: '',
    image:  '',
    id: '',
  },
};
