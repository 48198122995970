import gql from 'graphql-tag';

const getCompanyServicesQuery = gql`
query getCompanyServices($idsArray: [String!]) {
  getCompanyServices(idsArray: $idsArray) {
      title
      subtitle
      price
      duration
      image
      bookingLink
      location
      _id
  }
}
            `
export default getCompanyServicesQuery;
