import gql from 'graphql-tag';

const updateUserMutation = gql`
  mutation updateUser(
    $_id: String!
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $password: String
    $company: String
    $roles: [Role!]
    $coins: Int
    $steps: Int
    $verified: Boolean
  ) {
    updateUser(
      _id: $_id
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      email: $email
      password: $password
      coins: $coins
      steps: $steps
      company: $company
      roles: $roles
      verified: $verified
    ) {
      _id
      coins
      steps
      firstName
      lastName
      phone
      email
      company {
        _id
      }
      roles
      verified
    }
  }
`;

export default updateUserMutation;
