import gql from 'graphql-tag';

const createServiceMutation = gql`
mutation createService(
  $title: String!
  $subtitle: String!
  $price: String!
  $location: String!
  $image: String!
  $company: String!
  $duration: String!
  $bookingLink: String!
) {
  createService(
    title: $title
    subtitle: $subtitle
    location: $location
    company: $company
    duration: $duration
    bookingLink: $bookingLink
    image: $image
    price: $price
  ) {
    _id
    title
    subtitle
    image
    price
    location
    bookingLink
    duration
    company
  }
}

`;

export default createServiceMutation;
