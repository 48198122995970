import gql from 'graphql-tag';

const getCompanyEventsQuery = gql`
  query getCompanyEvents($_id: String!) {
    company(_id: $_id) {
      name
      sites
      events {
        _id
        title
        style
        instructor {
          _id
          firstName
          lastName
        }
        users {
          _id
          firstName
          lastName
          email
        }
        location
        date
        dateEnd
        duration
        totalSpots
        description
        image
        coins
        enablePush
        company {
          _id
        }
        isLive
        site
        zoomUrl
        hoursTillEvent
        verifiedUsers {
          _id
        }
      }
    }
  }
`;

export default getCompanyEventsQuery;
