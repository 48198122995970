import gql from 'graphql-tag';

const getCompaniesSlimQuery = gql`
  {
    companiesSlim {
      name
      _id
      logo
      whatsappLink
      slackLink
      services {
        _id
      }
      masterCode
      emailSuffix
      needsHealthDeclaration
      contentEnabled
      competitionMode
      leaderboardAvailable
      isPublic
      isPhoneOptional
      sites
      promotions {
        _id
      }
    }
  }
`;

export default getCompaniesSlimQuery;
