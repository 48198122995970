import gql from 'graphql-tag';

const getPromotionsQuery = gql`
query getCompanyPromotions($_id: String!) {
  company(_id: $_id) {
    promotions {
      title
      subtitle
      price
      tag
      image
      companies {
        companyId
        companyName
      }
      codes {
        value
        consumedBy
      }
      _id
    }
  }
}
            `
export default getPromotionsQuery;
