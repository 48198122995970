import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, Typography, Paper, CardMedia, Fab,
} from '@material-ui/core';
import * as XLSX from 'xlsx';
import moment from 'moment';
import Add from '@material-ui/icons/Add';

import { useApolloClient } from '@apollo/client';
import EventMakerCard from './EventMakerCard';
import EventMakerDialog from './EventMakerDialog';
import logo from '../../../Assets/logo.png';
import eventMakersQuery from '../../../graphql/event-maker/query/event-maker';
import CurrentCompanyContext from '../../../containers/CurrentCompany/CurrentCompanyContext';
import GET_MAKERS_STATISTICS from '../../../graphql/companies/query/company-maker-stats';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 8px',
    height: 450,
    flex: 1,

  },
  emptyText: {
    fontWeight: 400,
    color: '#75D6B2',
    marginTop: 16,
  },
  header: {
    display: 'flex',
    textAlign: 'center',
    width: '100%',
    marginBottom: 32,
    justifyContent: 'center',
    position: 'relative',
  },

});
function flattenData(data) {
  const result = [];

  data.forEach(item => {
    // Add the recurring event data
    const roundedNumber = Math.round(parseFloat(item.total_participation_rate));
    const roundedString = roundedNumber.toString();
    result.push({
      recurring_event_name: item.recurring_event_name,
      total_participation_rate: roundedString + "%",
      site: item?.site || "",
      // other fields if necessary
    });

    // Add each event as a child
    item.events.forEach(event => {
      result.push({
        recurring_event_name: "",  // Empty for child events
        total_participation_rate: "",  // Empty for child events
        // other event fields if necessary
        date: event.date,
        site: event.site || "",
        available_spots: event.available_spots,
        number_of_participants: event.number_of_participants,
        registered_user: "",
        attended_user: ""
      });
      const maxUsers = Math.max(event.registered_users.length, event.attended_users.length);

      for (let i = 0; i < maxUsers; i++) {
        result.push({
          recurring_event_name: "",  // Empty for child events
          total_participation_rate: "",  // Empty for child events
          // other event fields if necessary
          date: "",
          available_spots: "",
          number_of_participants: "",
          site: "",
          registered_user: event.registered_users[i] || "",
          attended_user: event.attended_users[i] || ""
        });
      }
    });
  });

  return result;
}
const EventMaker = ({ classes }) => {
  const client = useApolloClient();

  const { currentCompany } = React.useContext(CurrentCompanyContext);
  const [eventMakers, setEventMakers] = React.useState([]);
  console.log(currentCompany);
  const getEventMakers = () => {
    !!currentCompany
      && client.watchQuery({
        query: eventMakersQuery,
      })
        .subscribe(({ data }) => {
          const { eventMakers } = data;
          setEventMakers(eventMakers.filter((event) => event.company._id === currentCompany._id));
        }, (error) => {
          console.log(error);
        });
  };

  const [editEvent, setEditEvent] = React.useState();

  React.useEffect(() => {
    getEventMakers();
  }, [currentCompany]);
  async function handleMakersStats() {
    const eventMakers = await client.query({
      query: GET_MAKERS_STATISTICS,
      variables: { _id: currentCompany._id },
    });
    exportToExcel(eventMakers.data.getMakersStatistics);

  }
  const companyName = currentCompany && currentCompany.name ? currentCompany.name : '';
  function exportToExcel(data) {
    const flattened = flattenData(data);
    const ws = XLSX.utils.json_to_sheet(flattened);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "MakersStatistics");
    const fileName = companyName + "_ShaliboWellness_Statistics_" + ".xlsx";
    XLSX.writeFile(wb, fileName);
  }

  let companySites = currentCompany && currentCompany.sites ? currentCompany.sites : [];

  return (
    <div className={classes.grid}>
      <div className={classes.header}>
        <EventMakerDialog action="create"
          companySites={companySites} />

        {
          editEvent ?
            <EventMakerDialog action="edit" event={editEvent} companySites={companySites} isOpen={true} onClose={() => { setEditEvent(null) }} /> : null
        }

        <Typography gutterBottom variant="h4">
          Recurring Events Maker ({companyName})
        </Typography>
        <Fab
          style={{
            position: 'absolute',
            right: 0,
          }}
          variant="extended"
          color="primary"
          onClick={() => handleMakersStats()}>
          <Add /> Export Stats
        </Fab>
      </div>
      <Grid container spacing={2} style={{ marginBottom: 24, marginTop: 8 }}>

        {eventMakers.length < 1
          ? (
            <Paper className={classes.empty}>
              <div style={{ textAlign: 'center ' }}>
                <CardMedia style={{ width: 200, height: 200, margin: 'auto' }} image={logo} />
                <Typography variant="h4" className={classes.emptyText}>
                  There are no recurring events
                </Typography>
              </div>
            </Paper>
          )
          : eventMakers.map((event, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={4}>

              <EventMakerCard
                onEdit={() => { setEditEvent(event) }}
                onClose={() => setEditEvent(null)}
                event={event}
                companySites={companySites}
                title={event.title}
                instructor={event.instructor}
                location={event.location}
                date={event.date}
                totalSpots={event.totalSpots}
                takenSpots={event.users.length}
                description={event.description}
                image={event.image}
                company={event.company}
                hoursTillEvent={event.hoursTillEvent}
                id={event._id}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(EventMaker);
