import gql from 'graphql-tag';

const updateCompanyMutation = gql`
  mutation updateCompany(
    $_id: String!
    $name: String
    $logo: String
    $isPublic: Boolean
    $emailSuffix: String
    $eventsIds: [String!]
    $usersIds: [String!]
    $promotionsIds: [String]
    $leaderboardAvailable: Boolean
    $needsHealthDeclaration: Boolean
    $isPhoneOptional: Boolean
    $competitionMode: Boolean
    $contentEnabled: Boolean
    $whatsappLink: String
    $slackLink: String
    $sites: [String]
  ) {
    updateCompany(
      _id: $_id
      name: $name
      logo: $logo
      isPublic: $isPublic
      emailSuffix: $emailSuffix
      eventsIds: $eventsIds
      needsHealthDeclaration: $needsHealthDeclaration
      usersIds: $usersIds
      promotionsIds: $promotionsIds
      leaderboardAvailable: $leaderboardAvailable
      isPhoneOptional: $isPhoneOptional
      competitionMode: $competitionMode
      contentEnabled: $contentEnabled
      whatsappLink: $whatsappLink
      slackLink: $slackLink
      sites: $sites
    ) {
      _id
      name
      emailSuffix
      whatsappLink
      slackLink
      logo
      isPublic
      leaderboardAvailable
      competitionMode
      contentEnabled
      needsHealthDeclaration
      isPhoneOptional
      sites
      events {
        _id
        title
      }
      promotions {
        _id
        title
      }
      users {
        _id
        firstName
        lastName
      }
    }
  }
`;

export default updateCompanyMutation;
