import React, {useRef, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {CardHeader} from '@material-ui/core';
import { useApolloClient } from '@apollo/client/react';
import updatePromotionMutation from '../../../graphql/promotion/mutation/update-promotion';
import getCompaniesSlimQuery from '../../../graphql/companies/query/companies-slim';
import PromotionCompanies from './PromotionsCompanies';
import PromotionPicker from './PromotionPicker';

const R = require('ramda');

const styles = (theme) => ({
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    position: 'relative',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
    alignItems: 'flex-start',
    paddingTop: 0,
  },
  timeCaption: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  count: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    color: 'white',
  },
  scrollbarThumb: {
    background: 'linear-gradient(0deg, #0098f0 0%, #00f2c3 100%)',
    opacity: 0.5,
    borderRadius: 10,
  },
});

let companyToAddCheck = null;

function PromotionsModal(props) {
  const theme = useTheme();
  const companyPickerRef = useRef();
  const client = useApolloClient();

  const {promotion, classes} = props;
  const [companies, setCompanies] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ companyId, setCompanyId ] = React.useState(() => localStorage.getItem('company_id'));
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  useEffect(()=>{
     const getCompanies = () => {
    client.query({
      query: getCompaniesSlimQuery,
      
    }).then(({ data }) => {
      const companies = data.companiesSlim || [];
      console.log('companies', data)
      setCompanies(companies);
    }).catch((error) => {
        console.log(error);
      });
      
  }
  getCompanies();
  },[])
 

  return (
    <div>
      <div style={{cursor: 'pointer'}} onClick={handleClickOpen}>
        {props.children}
      </div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="body">
        <CardMedia
          className={classes.cardMedia}
          image={promotion.image}
          title={promotion.title}
          src="">
          <Typography className={classes.count} variant="h6">
            {`${'promotion av codes'}/${'total codes'} spots `}
          </Typography>
        </CardMedia>
        <CardHeader
          style={{textTransform: 'capitalize'}}
          title={`${promotion.title} with ${R.pathOr('Unknown', ['firstName'])(
            promotion.subtitle,
          )} ${R.pathOr('', ['lastName'])(promotion.price)}`}
          subheader={` ${
            promotion.subtitle
          }`}
          // subheaderTypographyProps={{style: {color: 'white'}}}
          // titleTypographyProps={{style: {color: 'white'}}}
        />

        <DialogContent className={classes.cardContent}>
          <Typography>{promotion.subtitle}</Typography>
        {/*  company promotoins instead attending  */}
          <PromotionCompanies
            promotion={promotion}
            companies={companies}
            style={{marginBottom: 20}}>
            <div
              style={{
                marginLeft: 26,
                display: 'flex',
                flexDirection: 'row',
              }}> {/* companies list minus allready added */}
              <PromotionPicker
                promotions={companies}
                onSelected={(selectedCompany) => {
                  companyToAddCheck = selectedCompany;
                }}
                ref={companyPickerRef}
              /> {/*  add promotions to company list in button  */}
              <Button
                onClick={() => {
                  const picker = companyPickerRef.current;
                  const addedCompany = picker?.state?.value;
                  if(addedCompany){
                    console.log(addedCompany, picker);
                  
                 client
                      .mutate({
                        mutation: updatePromotionMutation,
                        variables: {
                          companies: addedCompany,
                          _id: promotion._id
                        },
                      })
                      .then((value) => {
                        companyToAddCheck = null;
                        picker.onChange(null, {newValue: ''});
                        picker.onSuggestionsClearRequested();
                      });
                  }}}
                color="primary">
                Add
              </Button>
            </div>
          </PromotionCompanies>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(PromotionsModal);
