import gql from 'graphql-tag';

const getAllPromotionsQuery = gql`{
             promotions{
               _id
                title
                subtitle
                price
                tag
                image
                companies {
                  companyId
                  companyName
                }
                codes{
                    consumedBy
                     value
                }
                }
            }
            `;

export default getAllPromotionsQuery;