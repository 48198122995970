import gql from 'graphql-tag';

const getTrainerEventsQuery = gql`
  query getTrainerEvents($_id: String!) {
    getEventsForTrainer(instructor: $_id) {
      _id
      title
      style
      instructor {
        _id
        firstName
        lastName
      }
      users {
        _id
        firstName
        lastName
        email
      }
      company {
        _id
        name
        sites
      }
      location
      date
      dateEnd
      duration
      totalSpots
      description
      image
      coins
      enablePush
      isLive
      site
      zoomUrl
      verifiedUsers {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

export default getTrainerEventsQuery;
