import gql from 'graphql-tag';

const trainersQuery = gql`
  query {
    trainers {
      _id
      firstName
      lastName
      email
      phone
      roles
      verified
      avgRating
      EventsRating{
        eventId
        userId
        rating
        eventName
        eventDate
        comment
        userName
      }
      company{
      _id
      name
      }
    }
  }
`;

export default trainersQuery;
