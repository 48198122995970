import React, { useRef, useEffect, useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { CardHeader } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { useApolloClient } from '@apollo/client';
import AttendingUsers from './AttendingUsers';
import UserPicker from '../../../components/common/AutoSuggest';
import updateEventMutation from '../../../graphql/event/mutation/update-event';
import getCompanyUsersQuery from '../../../graphql/companies/query/get-users';
import getSingleEventDetails from '../../../graphql/event/query/single-event-details';
import verifyEventUsersMutation from '../../../graphql/event/mutation/verify-event-users';

const R = require('ramda');

const styles = (theme) => ({
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    position: 'relative',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
    alignItems: 'flex-start',
    paddingTop: 0,
  },
  timeCaption: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  count: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    color: 'white',
  },
  scrollbarThumb: {
    background: 'linear-gradient(0deg, #0098f0 0%, #00f2c3 100%)',
    opacity: 0.5,
    borderRadius: 10,
  },
});

// Be wary of infinite loops when using event and setInternalEvent

function EventModal(props) {
  const { event, setInternalEvent } = props;
  const theme = useTheme();
  const userPickerRef = useRef();
  const client = useApolloClient();

  const { classes, refetch } = props;
  const [companyUsers, setCompanyUsers] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const eventCompanyId = event?.company?._id ? event.company._id : localStorage.getItem('company_id');

  const _internalRefetch = () => {
    console.log('Refetching');
    (async () => {
      // Pulling many events can introduce stale data
      // So we refetch the event details after 5 seconds
      setTimeout(() => {
        refetch();
      }, 1500);
    })();
    if (!event._id) {
      return;
    }
    console.log('Refetching event details');
    setLoading(true);
    client.query({
      query: getSingleEventDetails,
      variables: { _id: event._id },
    }).then(({ error, data }) => {
      const eventResp = data?.getOneEventDetails;
      if (!eventResp) {
        return;
      }
      console.log('Refetched single event details', {
        eventResp,
      });

      setLoading(false);
      setInternalEvent(eventResp);
    }).catch((err) => {
      console.error(err)
    })
  };

  const internalRefetch = () => {
    // Changes could reflect later than commited.
    setTimeout(() => {
      _internalRefetch();
    }, 5000);
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }


  const currentEventUsers = useMemo(() => {
    return event?.users?.map((user) => user._id);
  }, [event]);

  const nonParticipatingUsers = useMemo(() => {
    return companyUsers.filter((user) => currentEventUsers.indexOf(user._id) === -1);
  }, [companyUsers, currentEventUsers]);


  useEffect(() => {
    if (!eventCompanyId || !isOpen) {
      return;
    }
    console.log('Event changed, fetching company users', {
      event,
    });
    client
      .query({
        query: getCompanyUsersQuery,
        variables: { _id: eventCompanyId },
      })
      .then(({ error, data }) => {
        if (!data?.company?.users) {
          return;
        }
        setCompanyUsers(data?.company?.users || []);
      }).catch((err) => { console.error(err) });
  }, [eventCompanyId, event, isOpen]);


  return (
    <div>
      <div style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        {props.children}
      </div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        style={
          isLoading
            ? {
              pointerEvents: 'none',
              opacity: '0.8',
              transition: 'opacity 0.4s ease',
            }
            : { transition: 'opacity 0.4s ease' }
        }
        scroll="body">
        <CardMedia
          className={classes.cardMedia}
          image={event.image}
          title={event.title}
          src="">
          <Typography className={classes.count} variant="h6">
            {`${event?.users?.length || 0}/${event.totalSpots} spots `}
          </Typography>
        </CardMedia>
        <CardHeader
          style={{ textTransform: 'capitalize' }}
          title={`${event.title} with ${R.pathOr('Unknown', ['firstName'])(
            event.instructor,
          )} ${R.pathOr('', ['lastName'])(event.instructor)}`}
          subheader={`${moment(event.date).format(' MMMM Do, HH:mm')} at ${event.location
            }`}
        />

        <DialogContent className={classes.cardContent}>
          <Typography>{event.description}</Typography>
          {
            isLoading ? <Typography>Loading Please Do Not Modify...</Typography> : (
              <div></div>
            )
          }
          <AttendingUsers
            eventUsers={event.users}
            verifiedUsers={event.verifiedUsers}
            refetch={internalRefetch}
            onVerify={(user) => {
              const oldVerifiedUsers = event.verifiedUsers;
              const oldVerifiedUsersId = oldVerifiedUsers.map(user => user._id);
              const newVerifiedUsers = oldVerifiedUsersId.includes(
                user._id,
              )
                ? oldVerifiedUsers.filter(
                  (userObj) => userObj._id !== user._id,
                )
                : [...oldVerifiedUsers, user];
              setLoading(true);
              setInternalEvent(() => {
                console.log('Setting verifiedUsers event', {
                  event,
                  newVerifiedUsers,
                });
                return { ...event, verifiedUsers: newVerifiedUsers };
              });
              client
                .mutate({
                  mutation: verifyEventUsersMutation,
                  variables: {
                    _id: event._id,
                    usersIds: newVerifiedUsers.map((user) => user._id),
                  },
                })
                .then((value) => {
                  setLoading(false);
                  console.log('Verify user calling setInternalEvent');
                  internalRefetch();
                });
            }}
            onRemove={(user) => {
              const updatedUsers = event?.users?.filter(
                (value) => value._id !== user._id,
              );
              // We also should remove from verified users
              const updatedVerifiedUsers = event?.verifiedUsers?.filter(
                (value) => value._id !== user._id,
              );
              setLoading(true);
              setInternalEvent(() => {
                console.log('Setting remove user event', {
                  event,
                  updatedUsers,
                  updatedVerifiedUsers
                });
                return { ...event, users: updatedUsers, verifiedUsers: updatedVerifiedUsers };
              });
              client
                .mutate({
                  mutation: updateEventMutation,
                  variables: {
                    _id: event._id,
                    users: updatedUsers.map((user) => user._id),
                    company: eventCompanyId
                  },
                })
                .then((value) => {
                  setLoading(false);
                  console.log('Remove user calling setInternalEvent');
                  internalRefetch();
                  setInternalEvent(value.data.updateEvent);
                });
            }}
            event={event}
            style={{ marginBottom: 20 }}>
            <div
              style={{
                marginLeft: 26,
                display: 'flex',
                flexDirection: 'row',
              }}>
              <UserPicker
                users={nonParticipatingUsers ? nonParticipatingUsers : []}
                ref={userPickerRef}
              />
              <Button
                onClick={() => {
                  const picker = userPickerRef.current;
                  const selectedUser = picker?.state?.value;
                  if (!selectedUser) {
                    return;
                  }
                  const selectedUserName = selectedUser.split(' ');
                  const selectedUserFound = nonParticipatingUsers.filter(
                    (user) => {
                      const userName = user.firstName + ' ' + user.lastName;
                      const userNameArray = userName.split(' ');
                      return userNameArray.every((elem, ind) => elem === selectedUserName[ind]);
                    })
                  const userToAdd = selectedUserFound[0];
                  const updatedEventUsers = [...currentEventUsers, userToAdd._id];
                  setLoading(true);
                  setInternalEvent(() => {
                    console.log('Add user setting event', {
                      event,
                      userToAdd,
                    });
                    // Note that by default, we are adding the user to both users and verifiedUsers
                    return {
                      ...event,
                      users: [...event.users, userToAdd],
                      verifiedUsers: [...event.verifiedUsers, userToAdd]
                    };
                  });
                  client
                    .mutate({
                      mutation: updateEventMutation,
                      variables: {
                        _id: event._id,
                        company: eventCompanyId,
                        users: updatedEventUsers
                      },
                    })
                    .then((value) => {
                      setLoading(false);
                      picker.onChange(null, { newValue: '' });
                      picker.onSuggestionsClearRequested();
                      console.log('Add user calling setInternalEvent');
                      setInternalEvent(value.data.updateEvent);
                      internalRefetch();
                    }).catch((err) => { console.error(err) });
                }}
                color="primary">
                Add
              </Button>
            </div>
          </AttendingUsers>
        </DialogContent>
        <DialogActions >
          <Button onClick={handleClose} style={{ paddingBottom: 50 }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default withStyles(styles)(EventModal);
