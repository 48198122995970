import gql from 'graphql-tag';

const getStatisticsQuery = gql`
query GetStatistics {
  getStatistics {
    todayLoggedInUsers {
      companyId
      userId
      count
      joinedEventsCount
    }
    weeklyLoggedInUsers {
      totalWeeklyStats {
        companyId
        userId
        count
        joinedEventsCount
      }
      dailyStats {
        sunday {
          companyId
          userId
          count
          joinedEventsCount
        }
        monday {
          companyId
          userId
          count
          joinedEventsCount
        }
        tuesday {
          companyId
          userId
          count
          joinedEventsCount
        }
        wednesday {
          companyId
          userId
          count
          joinedEventsCount
        }
        thursday {
          companyId
          userId
          count
          joinedEventsCount
        }
        friday {
          companyId
          userId
          count
          joinedEventsCount
        }
        saturday {
          companyId
          userId
          count
          joinedEventsCount
        }
      }
    }
    monthlyLoggedInUsers {
      totalMonthlyStats {
        companyId
        userId
        count
        joinedEventsCount
      }
      weeklyStats {
        totalWeeklyStats {
          companyId
          userId
          count
          joinedEventsCount
        }
        dailyStats {
          sunday {
            companyId
            userId
            count
            joinedEventsCount
          }
          monday {
            companyId
            userId
            count
            joinedEventsCount
          }
          tuesday {
            companyId
            userId
            count
            joinedEventsCount
          }
          wednesday {
            companyId
            userId
            count
            joinedEventsCount
          }
          thursday {
            companyId
            userId
            count
            joinedEventsCount
          }
          friday {
            companyId
            userId
            count
            joinedEventsCount
          }
          saturday {
            companyId
            userId
            count
            joinedEventsCount
          }
        }
      }
    }
    yearlyLoggedInUsers {
      totalYearlyStats {
        companyId
        userId
        count
        joinedEventsCount
      }
      monthlyStats {
        totalMonthlyStats {
          companyId
          userId
          count
          joinedEventsCount
        }
        weeklyStats {
          totalWeeklyStats {
            companyId
            userId
            count
            joinedEventsCount
          }
          dailyStats {
            sunday {
              companyId
              userId
              count
              joinedEventsCount
            }
            monday {
              companyId
              userId
              count
              joinedEventsCount
            }
            tuesday {
              companyId
              userId
              count
              joinedEventsCount
            }
            wednesday {
              companyId
              userId
              count
              joinedEventsCount
            }
            thursday {
              companyId
              userId
              count
              joinedEventsCount
            }
            friday {
              companyId
              userId
              count
              joinedEventsCount
            }
            saturday {
              companyId
              userId
              count
              joinedEventsCount
            }
          }
        }
      }
    }
    companies {
      companyId
      usersLoggedIn {
        companyId
        userId
        count
        joinedEventsCount
      }
      weeklyStats {
        totalWeeklyStats {
          companyId
          userId
          count
          joinedEventsCount
        }
        dailyStats {
          sunday {
            companyId
            userId
            count
            joinedEventsCount
          }
          monday {
            companyId
            userId
            count
            joinedEventsCount
          }
          tuesday {
            companyId
            userId
            count
            joinedEventsCount
          }
          wednesday {
            companyId
            userId
            count
            joinedEventsCount
          }
          thursday {
            companyId
            userId
            count
            joinedEventsCount
          }
          friday {
            companyId
            userId
            count
            joinedEventsCount
          }
          saturday {
            companyId
            userId
            count
            joinedEventsCount
          }
        }
      }
      monthlyStats {
        totalMonthlyStats {
          companyId
          userId
          count
          joinedEventsCount
        }
        weeklyStats {
          totalWeeklyStats {
            companyId
            userId
            count
            joinedEventsCount
          }
          dailyStats {
            sunday {
              companyId
              userId
              count
              joinedEventsCount
            }
            monday {
              companyId
              userId
              count
              joinedEventsCount
            }
            tuesday {
              companyId
              userId
              count
              joinedEventsCount
            }
            wednesday {
              companyId
              userId
              count
              joinedEventsCount
            }
            thursday {
              companyId
              userId
              count
              joinedEventsCount
            }
            friday {
              companyId
              userId
              count
              joinedEventsCount
            }
            saturday {
              companyId
              userId
              count
              joinedEventsCount
            }
          }
        }
      }
      yearlyStats {
        totalYearlyStats {
          companyId
          userId
          count
          joinedEventsCount
        }
        monthlyStats {
          totalMonthlyStats {
            companyId
            userId
            count
            joinedEventsCount
          }
          weeklyStats {
            totalWeeklyStats {
              companyId
              userId
              count
              joinedEventsCount
            }
            dailyStats {
              sunday {
                companyId
                userId
                count
                joinedEventsCount
              }
              monday {
                companyId
                userId
                count
                joinedEventsCount
              }
              tuesday {
                companyId
                userId
                count
                joinedEventsCount
              }
              wednesday {
                companyId
                userId
                count
                joinedEventsCount
              }
              thursday {
                companyId
                userId
                count
                joinedEventsCount
              }
              friday {
                companyId
                userId
                count
                joinedEventsCount
              }
              saturday {
                companyId
                userId
                count
                joinedEventsCount
              }
            }
          }
        }
      }
    }
  }
}
`;
export default getStatisticsQuery;