import React, { useState, useEffect } from "react"
import { useApolloClient } from '@apollo/client';
import {withStyles} from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import {Avatar, Typography, Tooltip, Switch} from "@material-ui/core"
import PushNotification from "../../../components/common/PushNotification"
import {stringToColor} from "../../../utils/random-color"
import CreateDialog from "../../../components/common/CreateDialog"
import getCompaniesSlimQuery from "../../../graphql/companies/query/companies-slim";
const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 15,
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    fontWeight: 700,
    color: "#222a42b3",
    fontSize: "0.875rem",
  },
  tableBody: {
    fontWeight: 400,
    color: "#222a42b3",
    fontSize: "0.875rem",
  },
})
function mergeCompanies(companyStats, company) {
  const mergedCompanies = [];

  companyStats.forEach(stats => {
    const matchingCompany = company.find(c => c._id === stats.companyId);
    if (matchingCompany) {
      mergedCompanies.push({ ...stats, ...matchingCompany });
    }
  });

  return mergedCompanies;
}

const Statistics = ({classes, statistics}) => {
  const client = useApolloClient();
  const [statsType, setStatsType] = useState(false);
  const [companies, setCompanies] = useState([{ name: 'Wait...', logo: '' }]);


  const getCompanies = (companyStatsArray) => {
    return client.query({
      query: getCompaniesSlimQuery,
    }).then(({ data }) =>  {
      const companies = data.companiesSlim || [];
      const mergedCompanies = mergeCompanies(companyStatsArray, companies);
      setCompanies(mergedCompanies); 
    },
      (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(statistics && statistics.companies) getCompanies(statistics.companies);
  }, [statistics.companies]);
  const today = new Date();
  const dayName = days[today.getDay() - 1];
  console.log(statistics.weeklyLoggedInUsers.dailyStats);
  return (
    <Paper className={classes.root}>
      <div style={{display: 'flex', justifyContent: 'space-around'}}>
        <Typography variant="h6" style={{fontWeight: 100}}>
          Total users statistics
        </Typography>
        <Tooltip title={'Stats Type'}>
      
                    <Switch
                      checked={statsType}
                      onChange={()=> setStatsType(!statsType)}
                      name="needsHealthDeclaration"
                      color="primary"
                    />
                   
        </Tooltip> 
        <Typography variant="h6" style={{fontWeight: 200}}>
          Per Company statistics
        </Typography>
      </div>
      {!statsType ?
      <>
      <Typography variant="h3" style={{fontWeight: 100, marginLeft:"30%"}}>
        Total logged in statistics
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>today</TableCell>
            <TableCell className={classes.tableHead}>this week</TableCell>
            <TableCell className={classes.tableHead}>this month</TableCell>
            <TableCell className={classes.tableHead}>this year</TableCell>
            <TableCell className={classes.tableHead}>Companies using</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
         <TableRow>
            <TableCell className={classes.tableHead}>{statistics.todayLoggedInUsers.length}</TableCell>
            <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.totalWeeklyStats.length}</TableCell>
            <TableCell className={classes.tableHead}>{statistics.monthlyLoggedInUsers.totalMonthlyStats.length}</TableCell>
            <TableCell className={classes.tableHead}>{statistics.yearlyLoggedInUsers.totalYearlyStats.length}</TableCell>
            <TableCell className={classes.tableHead}>{statistics.companies.length}</TableCell>
          </TableRow>
          
        </TableBody>
      </Table>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>sunday</TableCell>
            <TableCell className={classes.tableHead}>monday</TableCell>
            <TableCell className={classes.tableHead}>tuesday</TableCell>
            <TableCell className={classes.tableHead}>wednesday</TableCell>
            <TableCell className={classes.tableHead}>thursday</TableCell>
            <TableCell className={classes.tableHead}>friday</TableCell>
            <TableCell className={classes.tableHead}>saturday</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
         <TableRow>
              <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.dailyStats['sunday'].length}</TableCell>
              <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.dailyStats['monday'].length}</TableCell>
              <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.dailyStats['tuesday'].length}</TableCell>
              <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.dailyStats['wednesday'].length}</TableCell>
              <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.dailyStats['thursday'].length}</TableCell>
              <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.dailyStats['friday'].length}</TableCell>
              <TableCell className={classes.tableHead}>{statistics.weeklyLoggedInUsers.dailyStats['saturday'].length}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </>
        :
      <>
      <Typography variant="h3" style={{fontWeight: 100, marginLeft:"30%"}}>
        company statistics
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>name</TableCell>
            <TableCell className={classes.tableHead}>yesterday</TableCell>
            <TableCell className={classes.tableHead}>this week</TableCell>
            <TableCell className={classes.tableHead}>this month</TableCell>
            <TableCell className={classes.tableHead}>this year</TableCell>
            <TableCell className={classes.tableHead}>joined to events this month</TableCell>
            <TableCell className={classes.tableHead}>joined to events last month</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        { companies && companies.length > 0 && companies.map((company)=>{
          return (
            <TableRow>
              <TableCell className={classes.tableHead}>{company.name || 'loading'}</TableCell>
              <TableCell className={classes.tableHead}>{company.weeklyStats.dailyStats[dayName].length || '0'}</TableCell>
              <TableCell className={classes.tableHead}>{company.weeklyStats.totalWeeklyStats.length || '0'}</TableCell>
              <TableCell className={classes.tableHead}>{company.monthlyStats.totalMonthlyStats.length || '0'}</TableCell>
             <TableCell className={classes.tableHead}>{company.yearlyStats.totalYearlyStats.length || '0'}</TableCell>
             <TableCell className={classes.tableHead}>{company.monthlyStats.totalMonthlyStats.filter((user)=> user.joinedEventsCount > 0).length || '0'}</TableCell>
             <TableCell className={classes.tableHead}>{company.yearlyStats.monthlyStats[company.yearlyStats.monthlyStats.length - 1] && company.yearlyStats.monthlyStats[company.yearlyStats.monthlyStats.length - 1].totalMonthlyStats.filter((user)=> user.joinedEventsCount > 0).length  || '0'}</TableCell>
           </TableRow>
          )
        })  }  
        
        </TableBody>
      </Table>
      </>
      }
    </Paper>
  )
}

export default withStyles(styles)(Statistics)
