import gql from 'graphql-tag';

const updateServiceMutation = gql`
mutation updateService(
$_id: String!
  $title: String
  $subtitle: String
  $price: String
  $duration: String
  $bookingLink: String
  $location: String
  $image: String
) {
  updateService(
  _id: $_id
    title: $title
    subtitle: $subtitle
    location: $location
    image: $image
    price: $price
    duration: $duration
    bookingLink: $bookingLink

  ) {
    _id
    title
    subtitle
    image
    price
    duration
    bookingLink
    location
  }
}
`;

export default updateServiceMutation;
